import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {api} from "../../api/api.service";
import {AxiosError} from "axios";

const initialState = {
    message: '',
    variant: 'default'
};

export const handleNotAuthorized = () => {
    window.sessionStorage.removeItem('User');
    window.sessionStorage.removeItem('Access-Token');
    window.sessionStorage.removeItem('Refresh-Token');
    setTimeout(() => {
        window.location.reload();
    }, 3000);
}

export const errorSlice = createSlice({
    name: 'errorSlice',
    initialState,
    reducers: {
        resetError: () => initialState,
        setError: (state, action: PayloadAction<string>) => {
            state.variant = 'error';
            state.message = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addMatcher(api.endpoints.getClients.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.getUserByUserId.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints?.getRoles.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints?.insertUser.matchRejected, (state, action) => {

            if (action.payload instanceof AxiosError && (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401)) {
                handleNotAuthorized();
            }
        });
        builder.addMatcher(api.endpoints.editUserScheda.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.getMotorPatternList.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.getMotorPattern.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.insertMotorPattern.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.deleteMotorPattern.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.editMotorPattern.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.editUser.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.getMuscleGroupList.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.getMuscleGroup.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.insertMuscleGroup.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.deleteMuscleGroup.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.editMuscleGroup.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.getExercisesList.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.getExercise.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.insertExercise.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.deleteExercise.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.editExercise.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.getVariantList.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.getVariant.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.insertVariant.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.deleteVariant.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.editVariant.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.getTecnicheList.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.getTecnica.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.insertTecnica.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.deleteTecnica.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.editTecnica.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.getTUTList.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.getTUT.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.insertTUT.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.deleteTUT.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.editTUT.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.getRecList.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.getRec.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.insertRec.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.deleteRec.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.editRec.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.getProgrammazoniList.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.getProgrammazione.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.insertProgrammazione.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.deleteProgrammazione.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.editProgrammazione.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints?.getRepSchemeList.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints?.getRepScheme.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints?.insertRepScheme.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints?.deleteRepScheme.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints?.editRepScheme.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.getRepSchemeTypeList.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.getRepSchemeType.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.insertRepSchemeType.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.deleteRepSchemeType.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.editRepSchemeType.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.getEffortList.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.getEffort.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.insertEffort.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.deleteEffort.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.editEffort.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.editProgrammazione.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
        builder.addMatcher(api.endpoints.editProgrammazioneWeeks.matchRejected, (state, action) => {

            state.variant = 'error';
            if (action.payload instanceof AxiosError) {
                state.message = action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401 ? 'Sessione scaduta, effettua di nuovo il login per continuare' : action.payload.response?.data.message;
                if (action.payload.response?.data.statusCode === 403 || action.payload.response?.data.statusCode === 401) {
                    handleNotAuthorized();
                }
            }
        });
    }
});

export const { resetError, setError } = errorSlice.actions;

export default errorSlice.reducer;
